const location = {
  data () {
    return {
      coords: null,
      coordsBoundaries: {
        topLeft: null,
        bottomRight: null,
        rotatedTopLeft: null,
        rotatedBottomRight: null
      },
      positioningEngine: null
    }
  },
  // two lines
  // for getters
  computed: {
    locationParams () {
      if (this.pageData.System && !isNaN(this.pageData.DiffNorth) && this.pageData.Coords.topLeft && this.pageData.Coords.bottomRight) {
        return {
          system: this.pageData.System,
          diffNorth: this.pageData.DiffNorth,
          topLeft: this.pageData.Coords.topLeft,
          bottomRight: this.pageData.Coords.bottomRight
        }
      }
      return null
    }
  },
  methods: {
    initLocationSystem (params) {
      if (!this.$xp.positioning) return // return if positioning plugin is not installed
      this.initCoordsData(params)
      switch (params.system.toLowerCase()) {
        case 'gps':
          this.positioningEngine = this.$xp.positioning.getEngine('GPS')
          if (this.$xp.tracker) {
            this.$xp.tracker.subscribe(this.positioningEngine.parsePositionAndNotify.bind(this.positioningEngine))
            this.positioningEngine.startListeningToTracker()
          } else {
            this.positioningEngine.start()
          }
          this.positioningEngine.subscribe(this.setUtmStandPointIndicator)
          this.$once('hook:beforeDestroy', () => {
            if (this.$xp.tracker) {
              this.$xp.tracker.unsubscribe(this.positioningEngine.parsePositionAndNotify.bind(this.positioningEngine))
              this.positioningEngine.stopListeningToTracker()
            } else {
              this.positioningEngine.stop()
            }
            // console.log
          })
          break
        case 'pseudo': {
          const pseudoEngine = this.$xp.positioning.getEngine('Pseudo')
          const pseudoCoords = pseudoEngine.getLastPosition()
          pseudoCoords && this.setUtmStandPointIndicator(pseudoCoords)
          break
        }
        case 'wlan': {
          const fraunhoferEngine = this.$xp.positioning.getEngine('FraunhoferPosition')
          fraunhoferEngine.subscribe(this.setUtmStandPointIndicator)
          break
        }
      }
    },
    initCoordsData (params) {
      if (params.topLeft && params.bottomRight) {
        console.log('initCoordsData(', params, ')')
        this.coordsBoundaries.topLeft = { easting: params.topLeft.easting, northing: params.topLeft.northing }
        this.coordsBoundaries.bottomRight = { easting: params.bottomRight.easting, northing: params.bottomRight.northing }
        this.coordsCenter = {
          easting: this.coordsBoundaries.topLeft.easting + (this.coordsBoundaries.bottomRight.easting - this.coordsBoundaries.topLeft.easting) / 2,
          northing: this.coordsBoundaries.topLeft.northing + (this.coordsBoundaries.bottomRight.northing - this.coordsBoundaries.topLeft.northing) / 2
        }
        this.alphaRotation = -params.diffNorth * (Math.PI / 180)
        console.log('alphaRotation:', this.alphaRotation)
        this.coordsBoundaries.rotatedTopLeft = this.rotateUTM(this.coordsBoundaries.topLeft)
        this.coordsBoundaries.rotatedBottomRight = this.rotateUTM(this.coordsBoundaries.bottomRight)
        // this.pixelsInMeter = this.width / (this.coordsBoundaries.bottomRight.easting - this.coordsBoundaries.topLeft.easting)
        console.log('initCoordsData(', params, ') boundries=', this.coordsBoundaries, ', center:', this.coordsCenter)
      } else {
        console.log('initCoordsData(', params, ') attributes missing')
      }
    },
    rotateUTM (utmCoords) {
      if (this.alphaRotation) {
        return Object.assign({}, utmCoords, {
          easting: this.coordsCenter.easting + Math.cos(this.alphaRotation) * (utmCoords.easting - this.coordsCenter.easting) - Math.sin(this.alphaRotation) * (utmCoords.northing - this.coordsCenter.northing),
          northing: this.coordsCenter.northing + Math.sin(this.alphaRotation) * (utmCoords.easting - this.coordsCenter.easting) + Math.cos(this.alphaRotation) * (utmCoords.northing - this.coordsCenter.northing)
        })
      } else {
        return Object.assign({}, utmCoords)
      }
    },
    getFixedAccuracy (utmCoords) {
      const MIN_QUALITY_ACCURACY = 2.5 // 5m for quality = 0
      const MAX_QUALITY_ACCURACY = 35 // 15m for quality = 1
      const DEFAULT_PERCENT_ACCURACY = 0.1 // 10% of the biggest dimension
      const mapWidth = Math.abs(this.coordsBoundaries.rotatedTopLeft.easting - this.coordsBoundaries.rotatedBottomRight.easting)
      const mapHeight = Math.abs(this.coordsBoundaries.rotatedTopLeft.northing - this.coordsBoundaries.rotatedBottomRight.northing)
      const maxAccuracy = Math.min(mapHeight, mapWidth) * 0.5
      const minAccuracy = Math.max(mapWidth, mapHeight) * 0.05
      if (utmCoords.accuracy) {
        // if accuracy is set make sure its not bigger then the max and min accuracy
        return Math.max(minAccuracy, Math.min(utmCoords.accuracy, maxAccuracy)) * 2 // radius * 2 = width and height
      } else if (utmCoords.quality >= 0 && utmCoords.quality <= 1) {
        // if quality is between 0 and 1 then accuracy is between MIN_QUALITY_ACCURACY to MAX_QUALITY_ACCURACY and min and max accuracy
        const accuracyFromQuality = MAX_QUALITY_ACCURACY - (MAX_QUALITY_ACCURACY - MIN_QUALITY_ACCURACY) * utmCoords.quality
        return Math.max(minAccuracy, Math.min(accuracyFromQuality, maxAccuracy)) * 2
      } else {
        // if neither is set (PseudoLocation), accuracy is on default value
        return Math.max(mapWidth, mapHeight) * DEFAULT_PERCENT_ACCURACY
      }
    },
    setUtmStandPointIndicator (utmCoords) {
      const point = this.$el.querySelector('.js-stand-point')
      // console.log('setUtmStandPointIndicator()', utmCoords, this.geoData)
      if (utmCoords.easting && utmCoords.northing) {
        const fixedAccuracy = this.getFixedAccuracy(utmCoords)
        this.coords = utmCoords
        const rotatedCoords = this.rotateUTM(utmCoords)
        const topPercentage = 100 * Math.abs(rotatedCoords.northing - this.coordsBoundaries.rotatedTopLeft.northing) / Math.abs(this.coordsBoundaries.rotatedBottomRight.northing - this.coordsBoundaries.rotatedTopLeft.northing)
        const leftPercentage = 100 * Math.abs(rotatedCoords.easting - this.coordsBoundaries.rotatedTopLeft.easting) / Math.abs(this.coordsBoundaries.rotatedBottomRight.easting - this.coordsBoundaries.rotatedTopLeft.easting)
        const accuracyWidth = Math.round(fixedAccuracy / Math.abs(this.coordsBoundaries.rotatedBottomRight.easting - this.coordsBoundaries.rotatedTopLeft.easting) * 100)
        const accuracyHeight = Math.round(fixedAccuracy / Math.abs(this.coordsBoundaries.rotatedBottomRight.northing - this.coordsBoundaries.rotatedTopLeft.northing) * 100)
        point.style.width = accuracyWidth + '%'
        point.style.height = accuracyHeight + '%'
        point.style.top = topPercentage + '%'
        point.style.left = leftPercentage + '%'
        point.style.display = 'block'
        this.$emit('stand-point-indicator-position-changed', { topPercentage, leftPercentage })
        // console.log('setUtmStandPointIndicator show(easting=', utmCoords.easting, ', left=', Math.round(leftPercentage), '%, northing=', utmCoords.northing, ', top=', Math.round(topPercentage), '%)')
      } else {
        point.style.display = 'none'
        console.log('setUtmStandPointIndicator hide')
      }
    }
  }
}

export default location
